
import { computed, defineComponent, onMounted, PropType } from 'vue'
import ShopImageWithUpload from '@/components/shop/ShopImageWithUpload.vue'
import { useArticleStore } from '@/pinia/article/article.pinia'

type InputDefault = string|number|null

export default defineComponent({
	name: 'CreateShopStepFour',
	components: { ShopImageWithUpload },
	props: {
		imageUrl: String,
		isSubmitting: {
			type: Boolean,
			default: false
		},
		shopName: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		email: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		phoneNo: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		article: {
			type: String,
			default: ''
		},
		isShopChannel: {
			type: Boolean,
			default: false
		},
		shopDescription: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		shopAddress: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		facebook: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		instagram: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
		line: {
			type: [String, null] as PropType<InputDefault>,
			default: null
		},
	},
	emits: ['onNextStep', 'onBackStep'],
	setup(props, { emit }) {
		//
		const articleStore = useArticleStore()
		const currentArticle = computed(() => articleStore.getterGetArticleById(props.article))
		const nextStepHandle = () => {
			emit('onNextStep')
		}
		const backStepHandle = () => {
			emit('onBackStep')
		}

		onMounted(async () => {
			await articleStore.actionGetAllArticle()
		})

		return {
			nextStepHandle,
			backStepHandle,
			currentArticle
		}
	},
})
