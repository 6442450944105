
import { computed, defineComponent, onMounted, Prop, ref } from 'vue'
import { ElNotification } from 'element-plus'
import { ArticleData } from '@/api/article/article.type'
import ShopImageWithUpload from '@/components/shop/ShopImageWithUpload.vue'
import { phoneTh } from '@/utils/validate'
import { useReplaceInput } from '@/use/useReplaceInput'
import { useArticleStore } from '@/pinia/article/article.pinia'

export default defineComponent({
	name: 'CreateStepOne',
	components: { ShopImageWithUpload },
	props: {
		imageUrl: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
		isShopChannel: {
			type: Boolean,
			default: false
		},
		shopName: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
		shopDescription: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
		email: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
		phoneNo: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
		article: {
			type: [String, null],
			default: null
		} as Prop<string|null>,
	},
	emits: ['update:imageUrl', 'update:shopName', 'update:shopDescription', 'update:email', 'update:phoneNo', 'onNextStep', 'update:imageFile', 'update:article', 'update:isShopChannel'],
	setup(_, { emit }) {
		//
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const articleStore = useArticleStore()
		const stepOneFormRef = ref<any>(null)
		const articleList = computed<ArticleData[]>(() => articleStore.articles)
		const { decimalValueChecker } = useReplaceInput() 
		const beforeAvatarUpload = (file: any) => {
			const isValidType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
			const isLimit10mb = file.size / 1024 / 1024 < 10

			if (!isValidType) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'รูปแบบไฟล์ไม่ถูกต้อง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
			if (!isLimit10mb) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ขนาดรูปภาพต้องไม่เกิน 10mb',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})				
			}
			return isValidType && isLimit10mb
		}
		const handleAvatarSuccess = (_: any, file: any) => {
			emit('update:imageUrl', URL.createObjectURL(file.raw))
		}

		const nextStepHandle = async () => {
			const isFormValid = await stepOneFormRef.value?.validate()
			if (!isFormValid) return
			emit('onNextStep')
		}

		const onUploadComplete = async (upload: any) => {
			emit('update:imageFile', upload)
		}

		const onGetArticleList = async () => {
			await articleStore.actionGetAllArticle()
		}

		onMounted(() => {
			//
			onGetArticleList()
		})

		return {
			stepOneFormRef,
			beforeAvatarUpload,
			handleAvatarSuccess,
			nextStepHandle,
			onUploadComplete,
			articleList,
			phoneTh,
			decimalValueChecker,
		}
	},
})
