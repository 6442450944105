
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import StepFormDetail from '@/components/shop/create-steps/StepFormDetail.vue'
// import StepFormSocial from '@/components/shop/create-steps/StepFormSocial.vue'
import StepFormAddress from '@/components/shop/create-steps/StepFormAddress.vue'
import StepFormReview from '@/components/shop/create-steps/StepFormReview.vue'
import { createShop, ICreateShopPayload } from '@/api/shop.api'
import { ElNotification } from 'element-plus'
import firebase from 'firebase/app'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useAddressStore } from '@/pinia/address/address.pinia'
import { useShopStore } from '@/pinia/shop/shop.pinia'

export default defineComponent({
	name: 'ShopAddView',
	components: {
		StepFormDetail,
		// StepFormSocial,
		StepFormAddress,
		StepFormReview,
	},
	setup() {
		//
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const router = useRouter()
		const userStore = useUserStore()
		const addressStore = useAddressStore()
		const shopStore = useShopStore()
		const currentStep = ref<number>(0) // start 0
		const steps = computed(() => [
			{
				title: 'ทั่วไป',
			},
			// {
			// 	title: 'โซเชียล',
			// },
			{
				title: 'ที่อยู่',
			},
			{
				title: 'สรุป',
			},
		])
		const isSubmitting = ref(false)
		const stepOneData: any = reactive({
			imageFile: null,
			imageUrl: '',
			shopName: '',
			shopDescription: '',
			email: '',
			phoneNo: '',
			article: '',
			isShopChannel: false,
		})
		const imageUrlPreview = computed(() => {
			if (!stepOneData.imageUrl) return ''
			return URL.createObjectURL(stepOneData.imageUrl)
		})
		const stepTwoData = reactive({
			instagram: '',
			facebook: '',
			line: '',
		})
		const stepThreeData = reactive({
			address: '',
			province: null,
			district: null,
			subDistrict: null,
			zipCode: null,
		})
		const displayAddress = computed(() => {
			if (
				!stepThreeData.province ||
				!stepThreeData.district ||
				!stepThreeData.subDistrict
			)
				return ''
			const getAllName = addressStore.findAllName({
				provinceCode: stepThreeData.province as unknown as number,
				districtCode: stepThreeData.district as unknown as number,
				subDistrictCode: stepThreeData.subDistrict as unknown as number,
			})
			return `${stepThreeData.address} ${getAllName?.subDistrictName} ${getAllName?.districtName} ${getAllName?.provinceName} ${stepThreeData.zipCode}`
		})
		const nextStepHandle = async (data: any) => {
			if (currentStep.value === 1) {
				// ADD Data
				stepThreeData.address = data.address
				stepThreeData.province = data.province
				stepThreeData.district = data.district
				stepThreeData.subDistrict = data.subDistrict
				stepThreeData.zipCode = data.zipCode
			}

			if (currentStep.value >= 2) {
				// Upload shop image before commit data
				try {
					isSubmitting.value = true
					let pathUrl = null
					if (stepOneData.imageFile?.file) {
						const fileExtension = stepOneData.imageFile.file.name
							.split('.')
							.pop()
						const uploadPath = firebase
							.storage()
							.ref()
							.child(
								`shop/${userStore.userData?.uid}/${stepOneData.shopName}/defaultImage.${fileExtension}`
							)
						const snapshot = await uploadPath.put(
							stepOneData.imageFile.file
						)

						pathUrl = await snapshot.ref.getDownloadURL()
					}

					const payload: ICreateShopPayload = {
						name: stepOneData.shopName,
						desc: stepOneData.shopDescription,
						email: stepOneData.email,
						phoneNo: stepOneData.phoneNo,
						article: stepOneData.article,
						// instagramLink: stepTwoData.instagram,
						// facebookLink: stepTwoData.facebook,
						// lineLink: stepTwoData.line,
						image: pathUrl,
						address: {
							desc1: stepThreeData.address,
							provinceCode: stepThreeData.province,
							districtCode: stepThreeData.district,
							subDistrictCode: stepThreeData.subDistrict,
							zipCode: stepThreeData.zipCode,
						},
					}

					await createShop(payload)

					ElNotification({
						title: 'สำเร็จ',
						message: `สร้างร้าน ${payload.name}`,
						type: 'success',
						showClose: !isReactNativeWebview.value
					})

					return router.push('/shop')
				} catch (error) {
					console.log('error', error)
				} finally {
					isSubmitting.value = false
				}
			}

			currentStep.value++
		}

		const backStepHandle = () => {
			currentStep.value--
		}

		return {
			isSubmitting,
			imageUrlPreview,
			currentStep,
			steps,
			stepOneData,
			stepTwoData,
			stepThreeData,
			displayAddress,
			nextStepHandle,
			backStepHandle,
		}
	},
})
