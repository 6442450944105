
import { defineComponent, ref, reactive, PropType } from 'vue'
import AddressSystem from '@/components/core/AddressSystem.vue'

type InputDefault = string|number|null

export default defineComponent({
	name: 'CreateStepThree',
	props: {
		address: {
			type: String as PropType<InputDefault>,
			default: null
		},
		province: {
			type: String as PropType<InputDefault>,
			default: ''
		},
		district: {
			type: String as PropType<InputDefault>,
			default: ''
		},
		subDistrict: {
			type: String as PropType<InputDefault>,
			default: ''
		},
		zipCode: {
			type: String as PropType<InputDefault>,
			default: ''
		},
	},
	components: {
		AddressSystem
	},
	emits: ['onNextStep', 'onBackStep', 'update:province', 'update:district', 'update:subDistrict', 'update:zipCode'],
	setup(props, { emit }) {
		//
		const searchAddress = ref(null)
		const addressSystemDom = ref<any>(null)
		const addressData = reactive({
			address: props.address,
			province: props.province,
			district: props.district,
			subDistrict: props.subDistrict,
			zipCode: props.zipCode,
		})

		const nextStepHandle = async () => {
			const isValid = await addressSystemDom.value?.validateAll()
			if (!isValid) return
			emit('onNextStep', addressData)
		}
		const backStepHandle = () => {
			emit('onBackStep')
		}

		return {
			nextStepHandle,
			backStepHandle,
			searchAddress,
			addressData,
			addressSystemDom
		}
	},
})
